<template>
  <v-navigation-drawer
    class="menu rounded"
    floating
    v-model="$store.state.drawer"
    right
    :mini-variant.sync="$store.state.mini"
    :app="$store.state.break_point <= 991"
    :style="{
      height:
        $store.state.break_point <= 991 ? '100%!important' : 'auto!important',
    }"
  >
    <v-list-item class="px-2">

      <v-list-item-avatar>
        <v-img  v-if="$store.state.ergu_user.logo" :src="`${$store.state.def_path}/${$store.state.ergu_user.logo}`"></v-img>
        <v-img v-else :src="`${$store.state.def_path}/uploads/none_img.png`"></v-img>

      </v-list-item-avatar>

      <v-list-item-title
        >{{ $store.state.ergu_user.name.split(" ")[0] }}
      </v-list-item-title>

      <v-btn
        color="grey lighten-3"
        fab
        x-small
        depressed
        v-if="$store.state.break_point > 991"
        @click.stop="$store.state.mini = !$store.state.mini"
      >
        <v-icon>chevron_right</v-icon>
      </v-btn>
    </v-list-item>
    <v-divider></v-divider>
    <v-list dense v-for="(list, i) in links" :key="i">
      <v-list-item-title
        v-if="!$store.state.mini"
        class="grey--text lighten-3 caption px-4"
        >{{ list.title }}</v-list-item-title
      >
      <v-list-item
        active-class="primary white--text"
        v-for="item in list.list"
        :key="item.title"
        link
        :to="{ name: item.link }"
      >
        <v-list-item-icon class="me-2">
          <v-icon style="font-size: 18px">{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class="font-weight-bold">{{
            item.title
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item active-class="primary white--text" @click="logout()">
        <v-list-item-icon class="me-2">
          <v-icon style="font-size: 18px">logout</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class="font-weight-bold">להתנתק</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider v-if="i != list.list.length - 1"></v-divider>
    </v-list>

    <!-- <template slot="append">
      <v-divider></v-divider>
      <v-list-item class="px-2 align-center d-flex">
        <v-list-item-avatar>
          <v-img src="https://randomuser.me/api/portraits/men/85.jpg"></v-img>
        </v-list-item-avatar>

        <v-list-item-title>
          <h5>John Leider</h5>
          <p class="caption mb-0">lore ipiasdsa</p>
        </v-list-item-title>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="grey lighten-3"
              fab
              depressed
              x-small
              dark
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="black">more_vert</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="item in 3" :key="item">
              <v-list-item-title>{{ item }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list-item>
    </template> -->
  </v-navigation-drawer>
</template>
<script>
export default {
  data() {
    return {
      drawer: true,
      all_links: [
        {
          title: "",
          super: false,
          list: [
            {
              title: "ראשי",
              icon: "home",
              link: "home",
              permission: "dashboard access",
            },
            {
              title: "הזמנות",
              icon: "shopping_basket",
              link: "orders",
              permission: "orders access",
            },
            {
              title: "מוצרים",
              icon: "inventory_2",
              link: "products",
              permission: "products access",
            },
            {
              title: "קטגוריות",
              icon: "category",
              link: "sections",
              permission: "sections access",
            },
            {
              title: "מקורות",
              icon: "route",
              link: "resources",
              permission: "resources access",
            },
            {
              title: "אנשי קשר",
              icon: "ballot",
              link: "contacts_list",
              permission: "contacts access",
            },
            {
              title: "פעולות",
              icon: "flag",
              link: "logs",
              permission: "logs access",
            },

            {
              title: "עובדים",
              icon: "group",
              link: "employees",
              permission: "employees access",
            },
            {
              title: "מיקומים",
              icon: "map",
              link: "places",
              permission: "places access",
            },
            {
              title: "תשלומים",
              icon: "payments",
              link: "payments",
              permission: "payments access",
            },
            {
              title: "קהל יעד",
              icon: "token",
              link: "generations",
              permission: "generations access",
            },
            {
              title: "קבוצות",
              icon: "groups",
              link: "groups",
              permission: "groups access",
            },
            {
              title: "דוחות",
              icon: "article",
              link: "reports",
              permission: "reports access",
            },
            {
              title: "הגדרות",
              icon: "settings",
              link: "settings",
              permission: "settings access",
            },
          ],
        },
        {
          title: "",
          super: true,
          list: [
            {
              title: "עמותות",
              icon: "shopping_basket",
              link: "associations",
            },
          ],
        },
      ],

      mini: false,
    };
  },
  computed: {
    // links() {
    //   let links = [];
    //   this.all_links.forEach((e) => {
    //     if (e.super == this.$store.state.ergu_user.super_admin) {
    //       links.push(e);
    //     }
    //   });
    //   return links;
    // },

    links() {
      let links = [];
      this.all_links.forEach((e) => {
        if (e.super == this.$store.state.ergu_user.super_admin) {
          if (!e.super) {
            const list = [];
            e.list.forEach((item) => {
              if (
                this.$store.state.ergu_user.permissions.includes(
                  item.permission
                )
              ) {
                list.push(item);
              }
            });
            links.push({
              list: list,
            });
          } else {
            links.push(e);
          }
        }
      });
      return links;
    },
  },
  methods: {
    logout() {
      let vm = this;
      this.$store.commit("logout");
      setTimeout(() => {
        vm.$router.push({ name: "login" });
      }, 500);
    },
  },
};
</script>

<style lang="scss">
.menu {
  // min-width: 245px;
  height: calc(100vh - 150px) !important;
  min-height: calc(100vh - 150px) !important;
  @media (max-width: 991px) {
    height: 100vh !important;
    min-height: 100vh !important;
  }
  max-height: calc(100vh - 200px) !important;
  box-shadow: 0px 3px 1px -5px rgb(0 0 0 / 12%), 0px 2px 4px 0px rgb(0 0 0 / 0%),
    0px 1px 10px 0px rgb(0 0 0 / 12%);
}
</style>
