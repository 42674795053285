import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";
import AppAside from "@/layout/AppAside.vue";
Vue.use(VueRouter);
Vue.component("AppAside", AppAside);
import notfound from "@/views/not_found.vue";
const problem = () => import("@/views/problem");
const login = () => import("@/views/Login.vue");
//---Start association
const home = () => import("@/views/home.vue");
const settings = () => import("@/views/settings.vue");
const orders = () => import("@/views/orders/orders.vue");
const order = () => import("@/views/orders/order.vue");
const overdue = () => import("@/views/orders/overdue_products.vue");
const employees_page = () => import("@/views/employees/employees_page");
const employee = () => import("@/views/employees/employee");
const sections = () => import("@/views/sections");
const products = () => import("@/views/products/products");
const product = () => import("@/views/products/product");
const resources = () => import("@/views/resources");
const contacts_list = () => import("@/views/contacts_list");
const places = () => import("@/views/places");
const payments = () => import("@/views/payments");
const generations = () => import("@/views/generations");
const groups = () => import("@/views/groups");
const reports = () => import("@/views/reports");

//---End association

//---Start Super_admin
const associations = () => import("@/views/super_admin/associations");
//---End Super admin

const logs = () => import("@/views/logs");

const routes = [
  {
    path: "/associations",
    name: "associations",
    component: associations,
    meta: {
      title: "associations",
      requiresAuth: true,
      super_admin: true,
    },
  },
  //////////////------------- End Super_admin
  {
    path: "/home",
    name: "home",
    component: home,
    meta: {
      title: "home",
      requiresAuth: true,
      super_admin: false,
      permission: "dashboard access",
    },
  },
  {
    path: "/settings",
    name: "settings",
    component: settings,
    meta: {
      title: "settings",
      requiresAuth: true,
      super_admin: false,
      permission: "settings access",
    },
  },
  {
    path: "/about",
    name: "about",
    template: "<template>hello</template>",
    meta: {
      title: "about",
      requiresAuth: true,
      super_admin: false,
    },
  },
  //--------------- Employees
  {
    path: "/employees",
    name: "employees",
    component: employees_page,
    meta: {
      title: "employees",
      requiresAuth: true,
      super_admin: false,
      permission: "employees access",
    },
  },

  {
    path: "/employee",
    name: "employee",
    component: employee,
    meta: {
      title: "employee",
      requiresAuth: true,
      super_admin: false,
      permission: "employees access",
    },
  },

  //--------------- Sections
  {
    path: "/sections",
    name: "sections",
    component: sections,
    meta: {
      title: "sections",
      requiresAuth: true,
      super_admin: false,
      permission: "sections access",
    },
  },
  //--------------- Products
  {
    path: "/products",
    name: "products",
    component: products,
    meta: {
      title: "products",
      requiresAuth: true,
      super_admin: false,
      permission: "products access",
    },
  },

  {
    path: "/product/:id?",
    name: "product",
    component: product,
    meta: {
      title: "product",
      requiresAuth: true,
      super_admin: false,
      permission: "products access",
    },
  },

  //--------------- Resources
  {
    path: "/resources",
    name: "resources",
    component: resources,
    meta: {
      title: "resources",
      requiresAuth: true,
      super_admin: false,
      permission: "resources access",
    },
  },
  //--------------- contacts_list
  {
    path: "/contacts",
    name: "contacts_list",
    component: contacts_list,
    meta: {
      title: "contacts_list",
      requiresAuth: true,
      super_admin: false,
      permission: "contacts access",
    },
  },

  //--------------- orders
  {
    path: "/order/:id",
    name: "order",
    component: order,
    meta: {
      title: "order",
      requiresAuth: true,
      super_admin: false,
      permission: "orders access",
    },
  },
  {
    path: "/orders",
    name: "orders",
    component: orders,
    meta: {
      title: "orders",
      requiresAuth: true,
      super_admin: false,
      permission: "orders access",
    },
  },
  {
    path: "/overdue",
    name: "overdue",
    component: overdue,
    meta: {
      title: "overdue",
      requiresAuth: true,
      super_admin: false,
      permission: "orders access",
    },
  },

  {
    path: "/places",
    name: "places",
    component: places,
    meta: {
      title: "places",
      requiresAuth: true,
      super_admin: false,
      permission: "places access",
    },
  },

  {
    path: "/generations",
    name: "generations",
    component: generations,
    meta: {
      title: "generations",
      requiresAuth: true,
      super_admin: false,
      permission: "generations access",
    },
  },

  {
    path: "/groups",
    name: "groups",
    component: groups,
    meta: {
      title: "groups",
      requiresAuth: true,
      super_admin: false,
      permission: "groups access",
    },
  },
  {
    path: "/payments",
    name: "payments",
    component: payments,
    meta: {
      title: "payments",
      requiresAuth: true,
      super_admin: false,
      permission: "payments access",
    },
  },
  {
    path: "/reports",
    name: "reports",
    component: reports,
    meta: {
      title: "reports",
      requiresAuth: true,
      super_admin: false,
      permission: "reports access",
    },
  },

  {
    path: "/logs",
    name: "logs",
    component: logs,
    meta: {
      title: "logs",
      requiresAuth: true,
      super_admin: false,
      permission: "logs access",
    },
  },

  //--------------- public
  {
    path: "/",
    name: "login",
    component: login,
    meta: {
      title: "login",
      requiresAuth: false,
    },
  },

  {
    path: "*",
    name: "notfound",
    component: notfound,
    meta: {
      title: "notfound",
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;

// router.beforeEach(async (to, from, next) => {
//   document.title = to.meta.title;

//   if (to.name != "notfound") {
//     if (!to.matched.some((record) => record.meta.requiresAuth)) {
//       if (!store.getters.loggedin) {
//         next();
//       } else {
//         if (store.state.ergu_user.super_admin) {
//           next("/associations");
//         } else {
//           next("/home");
//         }
//       }
//     } else if (to.matched.some((record) => record.meta.requiresAuth)) {
//       if (!store.getters.loggedin) {
//         next({
//           name: "login",
//         });
//       } else {
//         if (store.state.ergu_user.super_admin) {
//           if (to.name != "associations") {
//             next("*");
//           } else {
//             next();
//           }
//         } else {
//           if (to.name == "associations") {
//             next("*");
//           } else {
//             next();
//           }
//         }
//       }
//     }
//   } else {
//     next();
//   }
// });

const permissions = store.state.ergu_user.permissions;
router.beforeEach(async (to, from, next) => {
  document.title = to.meta.title;

  if (to.name != "notfound") {
    if (!to.matched.some((record) => record.meta.requiresAuth)) {
      if (!store.getters.loggedin) {
        next();
      } else {
        if (store.state.ergu_user.super_admin) {
          next("/associations");
        } else {
          if (permissions.includes("products")) {
            next("/products");
          } else if (permissions.length != 0) {
            next({ name: store.getters.first_route });
          } else {
            next("/problem");
          }
        }
      }
    } else if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (!store.getters.loggedin) {
        next({
          name: "login",
        });
      } else {
        if (store.state.ergu_user.super_admin) {
          if (to.name != "associations") {
            next("*");
          } else {
            next();
          }
        } else {
          if (to.name == "associations") {
            next("*");
          } else {
            if (
              to.matched.some((record) =>
                permissions.includes(record.meta.permission)
              )
            ) {
              next();
            } else {
              next("*");
            }
          }
        }
      }
    }
  } else {
    next();
  }
});
